import {
  FooterParser,
  HeaderParser,
  isPopulatedReference,
  Meta,
  SectionParser,
  useActions,
  useGetFooterQuery,
  useGetJurisdictionListQuery,
  useGetHeaderQuery,
  useGetPageQuery,
  useGetMetaQuery,
  useGetLocalesQuery,
} from '@front/master';
import { resolveObservedGetStaticProps } from '@front/master/components/pages/SectionsPage';
import {
  DefaultCard,
  DefaultCards,
  ETitleSize,
  Breadcrumbs,
  Container,
  EContainerVariant,
} from '@front/shared/ds';
import { addJurisdictionSupport, getGaId } from '@front/shared/utils';
import { Page, Section } from '@shared/master-types';
import clsx from 'clsx';
import React, { useMemo } from 'react';

import { variantOfHero } from '../../sections/HeroBackground';
import HeroBackground from '../../sections/HeroBackground/HeroBackground';
import { HeroSection } from '../../sections/HeroSection';

export interface ISectionsPageProps {
  id: string;
  locale: string;
  jurisdiction: string;
  draft: boolean;
}

const SectionsPage: React.FC<ISectionsPageProps> = props => {
  const { draft, id, locale, jurisdiction } = props;

  const { data: headerDefaultObject } = useGetHeaderQuery({ locale });
  const { data: footerDefaultObject } = useGetFooterQuery({ locale });
  const { data: jurisdictionListObject } = useGetJurisdictionListQuery();
  const { data: localesObject } = useGetLocalesQuery({ locale, draft });
  const { data: meta } = useGetMetaQuery({ locale });
  const { data } = useGetPageQuery({ id, draft, locale, depth: 3 });

  const locales = localesObject?.docs;
  const headerDefault = headerDefaultObject?.docs?.[0];
  const footerDefault = footerDefaultObject?.docs?.[0];
  const jurisdictionList = jurisdictionListObject?.docs;
  const defaultJurisdiction = jurisdictionList?.[0];

  const currentJurisdiction =
    jurisdictionList?.filter(j => j.slug === jurisdiction)?.[0] ??
    defaultJurisdiction;

  const { header, footer } = currentJurisdiction || {};

  const page = data as Page;

  const sectionsGroup = page?.parts?.sections;
  const jurSectionsGroup = sectionsGroup?.jurisdictions?.find(
    item =>
      (typeof item.jurisdiction !== 'string' &&
        item.jurisdiction.slug === jurisdiction) ||
      item.jurisdiction === currentJurisdiction?.id,
  );

  const isPageCanonical = jurSectionsGroup?.isPageCanonical;
  const sections = ((jurSectionsGroup?.sections ??
    sectionsGroup?.defaultSections) ||
    []) as Section[];

  let hero = isPopulatedReference(page?.parts?.hero)
    ? page?.parts?.hero
    : undefined;
  const jurisdictionHeroRel = page?.parts?.heroJurisdictions?.find(
    rel => rel.jurisdiction === currentJurisdiction?.id,
  );
  const jurisdictionHero = isPopulatedReference(jurisdictionHeroRel?.hero)
    ? jurisdictionHeroRel?.hero
    : undefined;
  hero = jurisdictionHero || hero;

  const heroActions = addJurisdictionSupport({
    jurisdictionList,
    localeList: locales,
    slug: currentJurisdiction?.slug,
    actionList: useActions(hero?.actions),
  });

  const cards = useMemo(() => hero?.cards || [], [hero?.cards]);
  const isShowBreadcrumbs = !page?.isBreadcrumbsHidden && !hero;

  const banners: React.ComponentProps<typeof DefaultCard>[] = useMemo(() => {
    return cards.map(card => ({
      title: card.title,
      titleSize: ETitleSize.Small,
      description: card.description,
    }));
  }, [cards]);
  return (
    <>
      {(header || headerDefault) && (
        <HeaderParser
          headers={header ?? headerDefault}
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={currentJurisdiction}
        />
      )}
      <main>
        <Meta
          {...page?.meta}
          {...meta}
          isPageCanonical={isPageCanonical}
          url={page?.url}
        />
        {isShowBreadcrumbs && page?.breadcrumbs && (
          <Breadcrumbs
            items={page.breadcrumbs}
            locale={locales?.length ? locale : ''}
          />
        )}
        {hero?.images && hero.images in variantOfHero && (
          <div
            className={clsx('overflow-hidden 2xl:pb-16', {
              'pb-12': cards.length !== 0,
            })}
          >
            <Container
              variant={EContainerVariant.Lead}
              dataGaId={getGaId(hero.images, 'hero')}
            >
              <HeroBackground {...variantOfHero[hero.images]}>
                <HeroSection
                  title={hero.title}
                  subtitle={hero.subtitle}
                  actions={heroActions}
                />
              </HeroBackground>
            </Container>
            {cards.length !== 0 && (
              <div className='mt-6 2xl:mt-4'>
                <DefaultCards
                  banners={banners}
                  align={'Center'}
                  columns={hero?.cards?.length === 3 ? '3' : '2'}
                  horizontalScroll={'Mobile'}
                />
              </div>
            )}
          </div>
        )}
        {sections?.map(section => (
          <SectionParser
            key={section.id}
            section={section}
            locales={locales}
            jurisdictionList={jurisdictionList}
            jurisdictionItem={currentJurisdiction}
          />
        ))}
      </main>
      {(footer || footerDefault) && (
        <FooterParser
          footers={footer ?? footerDefault}
          locales={locales}
          jurisdictionList={jurisdictionList}
          jurisdictionItem={currentJurisdiction}
        />
      )}
    </>
  );
};

export { getStaticPaths } from '@front/master/components/pages/SectionsPage';

export const getStaticProps = resolveObservedGetStaticProps({
  jurisdictions: true,
  depth: 3,
});

export default SectionsPage;
